<template>
  <b-sidebar
    id="operation-modal"
    v-model="sidebar"
    bg-variant="white"
    no-close-on-backdrop
    right
    backdrop
    shadow
    width="680px"
    @hidden="$emit('update:isShowModal', false)"
    @shown="showModal"
  >
    <template #header="{ hide }">
      <feather-icon
        icon="XIcon"
        class="mt-1"
        @click="hide"
      />
      <h6 class="mb-0 mt-1 ml-2">
        {{ $t('finance.income') }}
      </h6>
    </template>
    <validation-observer ref="infoOperation">
      <b-overlay
        :show="finance_loading"
        opacity="0.6"
        rounded="lg"
      >
        <b-col
          md="12"
          class="mt-2"
        >
          <!-- <b-row class="pb-1">
              <b-col md="4">
                <span>{{ $t('clients.contract') }}</span>
              </b-col>
              <b-col md="8">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('clients.contract')"
                  rules="required"
                >
                  <app-select
                    v-model="transaction.contract"
                    label="contract_number"
                    :options="GET_CONTRACTS_V2"
                    :item-selected.sync="transaction.contract"
                    :lazy-fetch-data="FETCH_CONTRACTS"
                    :search="FETCH_CONTRACTS"
                    :reduce="opt => opt.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row> -->

          <b-row class="pb-1">
            <b-col md="4">
              <span>{{ $t('finance.payment_type') }}</span>
            </b-col>
            <b-col md="8">
              <validation-provider
                #default="{ errors }"
                :name="$t('finance.payment_type')"
                rules="required"
              >
                <v-select
                  v-model="transaction.payment_type"
                  :options="payment_type_options"
                  :get-option-label="type => type.title[$i18n.locale]"
                  :reduce="type => type.value"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="pb-1">
            <b-col md="4">
              <span>{{ $t('finance.income_type') }}</span>
            </b-col>
            <b-col md="8">
              <validation-provider
                #default="{ errors }"
                :name="$t('finance.income_type')"
                rules="required"
              >
                <v-select
                  v-model="transaction.income_type"
                  :options="income_type_options"
                  :get-option-label="type => type.title[$i18n.locale]"
                  :reduce="type => type.value"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="pb-1">
            <b-col md="4">
              <span>{{ $t('finance.summ') }}</span>
            </b-col>
            <b-col md="8">
              <validation-provider
                #default="{ errors }"
                :name="$t('finance.summ')"
                rules="required"
              >
                <cleave
                  id="number"
                  v-model="transaction.amount"
                  class="form-control"
                  :raw="true"
                  :options="options"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="pb-1">
            <b-col md="4">
              <span class="pt-1">{{ $t('finance.description') }}</span>
            </b-col>
            <b-col md="8">
              <validation-provider
                #default="{ errors }"
                :name="$t('finance.description')"
              >
                <b-form-textarea
                  v-model="transaction.description"
                  rows="2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              md="12"
              class="pt-2 d-flex justify-content-end"
            >
              <b-button
                variant="secondary"
                class="mr-1"
                @click="close"
              >
                {{ $t('general.cancel') }}
              </b-button>
              <b-button
                variant="success"
                @click="onSubmit"
              >
                {{ $t('general.save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-overlay>
    </validation-observer>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BRow,
  BCol,
  BFormTextarea,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// import AppSelect from '../AppSelect.vue'

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BFormTextarea,
    BButton,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    Cleave,
    BOverlay,
    // AppSelect,
  },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    operation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      finance_loading: false,
      required,
      sidebar: false,
      type: 'income',
      income_type_options: [
        {
          title: {
            ru: 'Продажа',
            uz: 'Sotuv',
          },
          value: 'sale',
        },
        {
          title: {
            ru: 'Дебитор',
            uz: 'Debitor',
          },
          value: 'debtor',
        },
      ],
      payment_type_options: [{
        title: {
          ru: 'Наличные',
          uz: 'Naqd',
        },
        value: 'cash',
      },
      {
        title: {
          ru: 'Без наличные',
          uz: 'Naqdmas',
        },
        value: 'noncash',
      },
      {
        title: {
          ru: 'Бартер',
          uz: 'Barter',
        },
        value: 'barter',
      }],
      transaction: {
        id: null,
        type: '',
        paid_at: '',
        contract: '',
        income_cashbox: null,
        cost_item: 1,
        amount: '',
        description: '',
      },
      transactionIncomeTypes: [],
      options: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }
  },
  watch: {
    isShowModal(val) {
      this.sidebar = val
    },
    type(newValue) {
      this.filterIncomeTypes(newValue)
    },
  },
  methods: {
    onSubmit() {
      this.$refs.infoOperation.validate().then(async success => {
        if (success) {
          console.log('success')
        }
      })
    },
    typeChange(type) {
      this.transaction.type = type
    },
    filterIncomeTypes(type) {
      this.transactionIncomeTypes = this.getTransactionIncomeType.results
        .filter(item => item.type === type)
        .map(item => ({
          ...item,
          title: item.name.ru,
        }))
    },
    close() {
      this.sidebar = false
      this.$nextTick(() => {
        this.$refs.infoOperation.reset()
      })
    },

    showModal() {
      console.log('show')
    },
  },
}
</script>
